import type { StrapiMedia } from "./strapi/Strapi";

export enum PartnerGroup {
  COMMON = "common",
  RESTAURATION_COLLECTIVE = "restauration-collective",
  HOTELLERIE = "hotellerie",
  CAFE_RESTAURANT = "cafe-restaurant",
  COMMERCE_DE_BOUCHE = "commerce-de-bouche",
  TRAITEUR_EVENEMENTIEL = "traiteur-evenementiel",
  LOGISTIQUE_VENTE = "logistique-vente",
  JO2024 = "jo2024",
  LP_ETAB_LYON = "lp-etab-lyon",
  INTERIM_RESTAURATION = "interim-restauration",
}

export type Partner<T = StrapiMedia> = {
  id: number;
  alt: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  logo: T | null;
  url: string | null;
};

export type Partners = Partner<StrapiMedia>[];
